import React, { useState, useCallback } from 'react';
import {
	Box,
	Select,
	MenuItem,
	TextField,
	InputAdornment,
	SelectChangeEvent,
	Button,
	Dialog,
	Chip,
	Autocomplete,
	Popper,
	IconButton,
	Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import OrderForm from './OrderForm';
import { SendMessageFunction } from '../../../../websocket/hooks/types';
import { useOrdersContext } from './context/OrdersContext';
import {
	armChipStyle,
	lineplanChipStyle,
	loadedChipStyle,
	machineChipStyle,
} from './tables/OrderTableStyles';
import ExcelImportDialog from '../../../../websocket/utils/ExcelImportDialog';
import debounce from 'lodash/debounce';
import { Order } from './hooks/Interfaces';
import OrderSyncDialog from './orderSync/OrderSyncDialog';
import ExportOrdersButton from './ExportOrdersButton';
import CloseIcon from '@mui/icons-material/Close';

interface OrderActionsProps {
	orders: Order[];
	pageState: any;
	sendMessage: SendMessageFunction;
	syncEnabled: boolean;
}

const OrderActions: React.FC<OrderActionsProps> = ({
	pageState,
	sendMessage,
	syncEnabled,
	orders,
}) => {
	// Local states for each of the chip types
	const [autocomplete, setAutocomplete] = useState<string>('');  // Manage search query locally
	const [selectedMachines, setSelectedMachines] = useState<string[]>(pageState.filters.machines || []);
	const [selectedLoaded, setSelectedLoaded] = useState<string[]>(pageState.filters.loaded || []);
	const [selectedLineplans, setSelectedLineplans] = useState<string[]>(pageState.filters.lineplans || []);
	const [hoveredChip, setHoveredChip] = useState<string | null>(null); // To track which chip is hovered

	const [orderFormOpen, setOrderFormOpen] = useState(false);
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const [dialogType, setDialogType] = useState<'machines' | 'loaded' | 'lineplans'>('machines');
	const [orderType, setOrderType] = useState(0);
	const [searchQuery, setSearchQuery] = useState(pageState.filters.search || '');

	const debouncedSendMessage = useCallback(
		debounce((query) => {
			pageState.filters.search = query;
			sendMessage('update_page_state', { page_state: pageState });
		}, 500),
		[pageState, sendMessage]
	);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
		debouncedSendMessage(event.target.value);
	};

	const handleClearSearch = () => {
		setSearchQuery('');
		pageState.filters.search = ''
		debouncedSendMessage('')
	}

	const handleNewOrder = () => {
		setOrderFormOpen(true);
	};

	const closeOrderForm = () => {
		setOrderFormOpen(false);
	};

	const handleTypeChange = (event: SelectChangeEvent<number>) => {
		const newType = event.target.value as number;
		setOrderType(newType);
		pageState.filters.type = newType;
		sendMessage('update_page_state', { page_state: pageState });
	};

	const openDialog = (type: 'machines' | 'loaded' | 'lineplans') => {
		setDialogType(type);
		setDialogOpen(true)
		setAutocomplete(''); // Reset the search query when opening a new dialog
	};

	const closeDialog = () => {
		setDialogOpen(false)
	};

	const handleSelectionChange = (type: string, selectedValues: string[]) => {
		switch (type) {
			case 'machines':
				setSelectedMachines(selectedValues);
				pageState.filters.machines = selectedValues;
				break;
			case 'loaded':
				setSelectedLoaded(selectedValues);
				pageState.filters.loaded = selectedValues;
				break;
			case 'lineplans':
				pageState.filters.lineplans = selectedValues;
				setSelectedLineplans(selectedValues);
				break;
			default:
				break;
		}
		sendMessage('update_page_state', { page_state: pageState });
	};

	// Function to clear all selected options for a chip type
	const clearSelections = (type: string) => {
		handleSelectionChange(type, []);
	};

	const requiredHeaders = [
		'Factory',
		'Number',
		'Item',
		'Description',
		'Quantity',
		'Parent Order',
		'Order Date',
		'Due Date',
		'Ship Date',
		'Closed Date',
		'Order Status',
		'Special Instructions',
	];

	const { items, machines, lineplans, permissions } = useOrdersContext();

	// Chip-to-dialog mappings for clarity
	const chipMappings = {
		loaded: { label: 'Loaded', style: loadedChipStyle, options: machines, value: selectedLoaded },
		machines: { label: 'Machine', style: machineChipStyle, options: [{ title: 'Not Scheduled', id: null }, ...machines], value: selectedMachines },
		lineplans: { label: 'Lineplan', style: lineplanChipStyle, options: [{ title: 'Not Scheduled', id: null }, ...lineplans], value: selectedLineplans },
	};

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					px: 2,
					mb: 1,
					mt: 2,
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
					{permissions.create &&
						<Button
							sx={{ borderRadius: 3 }}
							variant="contained"
							color="primary"
							onClick={handleNewOrder}
						>
							+ New Order
						</Button>}
					{permissions.create && (
						syncEnabled ? (
							<OrderSyncDialog />
						) : (
							<ExcelImportDialog
								requiredHeaders={requiredHeaders}
								buttonSx={{ borderRadius: 3 }}
								buttonText="Import Orders"
								sendMessage={sendMessage}
							/>
						)
					)}
					<ExportOrdersButton orders={orders} />
				</Box>

				<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
					<Select
						value={orderType}
						onChange={handleTypeChange}
						variant="outlined"
						size="small"
						sx={{
							minWidth: 100,
							height: 40,
							boxSizing: 'border-box',
							borderRadius: 3,
						}}
						MenuProps={{
							PaperProps: {
								sx: {
									borderRadius: 3,
									mt: 0.5,
								},
							},
							MenuListProps: {
								sx: {
									py: 0
								}
							}
						}}
					>
						<MenuItem value={0}>Open</MenuItem>
						<MenuItem value={1}>Closed</MenuItem>
					</Select>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						{(Object.keys(chipMappings) as Array<'machines' | 'loaded' | 'lineplans'>).map(
							(type) => (
								
									<Chip
										label={`${chipMappings[type].label} (${chipMappings[type].value.length})`}
										sx={chipMappings[type].style}
										onClick={() => openDialog(type)}
										onMouseEnter={() => setHoveredChip(type)}
										onMouseLeave={() => setHoveredChip(null)}
										deleteIcon={
											hoveredChip === type ? (
												<Tooltip title={`Clear ${type}`} key={type}>
													<IconButton
														size="small"
														onClick={(e) => {
															e.stopPropagation(); // Prevent the chip click event
															clearSelections(type);
														}}
														sx={{ p: 0.125 }}
													>
														<CloseIcon fontSize="small" />
													</IconButton>
												</Tooltip>
											) : undefined // Use undefined instead of null
										}
										onDelete={() => clearSelections(type)} // Handles clearing on click of the delete icon
									/>
								
							)
						)}
					</Box>
					<TextField
						placeholder="Search..."
						variant="outlined"
						size="small"
						value={searchQuery}
						onChange={handleSearchChange}
						sx={{
							borderRadius: '20px',
							'& .MuiOutlinedInput-root': {
								borderRadius: '20px',
								height: 40,
								boxSizing: 'border-box',
							},
							width: 250,
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{searchQuery ? (
										<IconButton sx={{ p: 0 }} onClick={handleClearSearch}>
											<ClearIcon />
										</IconButton>
									) : (
										<SearchIcon />
									)}
								</InputAdornment>
							),
						}}
					/>
				</Box>
			</Box>

			{/* Dialog for Machine, Arm, Loaded, Lineplan */}
			<Dialog open={dialogOpen} onClose={closeDialog} PaperProps={{ sx: { borderRadius: 15 } }} maxWidth="sm" fullWidth>
				<Box sx={{ p: 3 }}>
					<Autocomplete
						multiple
						options={chipMappings[dialogType].options.map((option) => option.title)}
						value={chipMappings[dialogType]?.value}
						disableCloseOnSelect  // Prevent the dropdown from closing after selection
						clearOnBlur={false}    // Prevent clearing the search on blur
						clearOnEscape={false}  // Prevent clearing the search when escape is pressed
						inputValue={autocomplete}
						onInputChange={(_, newInputValue) => setAutocomplete(newInputValue)} // Handle input changes locally
						onChange={(_, newValue) => handleSelectionChange(dialogType || '', newValue)}
						renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Chip
									label={option}
									sx={chipMappings[dialogType || 'machines'].style} // Apply the same chip style as the clicked chip
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label={`Select ${dialogType}`}
								variant="outlined"
								sx={{
									'& .MuiOutlinedInput-root': {
										borderRadius: '30px',  // This makes the input box rounded
									},
									'& .MuiOutlinedInput-input': {
										padding: '10px',  // Adjust padding as needed
									},
								}}
							/>
						)}
						PaperComponent={({ children }) => (
							<div style={{ borderRadius: '10px', backgroundColor: 'white' }}>
								{children}
							</div>
						)}
						PopperComponent={(props) => (
							<Popper
								{...props}
								modifiers={[
									{
										name: 'offset',
										options: {
											offset: [0, 4], // Adjust dropdown offset
										},
									},
								]}
							/>
						)}
					/>
				</Box>
			</Dialog>

			<Dialog open={orderFormOpen} onClose={closeOrderForm} maxWidth="md" fullWidth>
				<OrderForm
					id={undefined}
					open={orderFormOpen}
					itemList={items}
					closeForm={() => closeOrderForm()}
				/>
			</Dialog>
		</>
	);
};

export default OrderActions;