import { useTheme } from "@mui/material";

// const theme = useTheme()

export const stickyColumnHeaderStyle = {
	position: 'sticky',
	left: 0,
	backgroundColor: '#f4f9ff',
	zIndex: 4,
	pl: 4,
	fontWeight: 'bold',
	borderRight: '1px solid #ddd',
	textAlign: 'center',
	// borderBottom: `2px solid #ddd`
};

export const stickyColumnStyle = {
	position: 'sticky',
	left: 0,
	backgroundColor: 'inherit',
	zIndex: 2,
	width: {
		xs: '100px',
		sm: '150px',
		md: '200px',
	},
	textAlign: 'center',
	fontWeight: 'bold',
	borderRight: '1px solid #ddd',
	whiteSpace: 'nowrap',      // Prevent text from wrapping
	overflow: 'hidden',         // Ensure the text doesn't overflow
	textOverflow: 'ellipsis',   // Show ellipsis for truncated text
	// borderBottom: `2px solid #ddd`
};

export const stickyHeaderStyle = {
	position: 'sticky',
	top: 0,
	backgroundColor: '#f4f9ff',
	zIndex: 3,
};

export const cellStyle = {
	width: {
		xs: '100px',
		sm: '150px',
		md: '200px',
	},
	textAlign: 'center',
	whiteSpace: 'nowrap',      // Prevent text from wrapping
	overflow: 'hidden',         // Ensure the text doesn't overflow
	textOverflow: 'ellipsis',   // Show ellipsis for truncated text
	// borderBottom: `2px solid #ddd`
};

export const unitStyle = {
	width: {
		xs: '50px',
		sm: '50px',
		md: '50px',
	},
	textAlign: 'center',
	// borderBottom: `2px solid #ddd`
};

export const machineChipStyle = {
	backgroundColor: '#96d2ea',
	color: 'black',
	margin: '2px',
};

export const armChipStyle = {
	backgroundColor: '#eadc96',
	color: 'black',
	margin: '2px',
};

export const loadedChipStyle = {
	backgroundColor: '#90EE90',
	color: 'black',
	margin: '2px',
};

export const lineplanChipStyle = {
	backgroundColor: '#EABFFF',
	color: 'black',
	margin: '2px',
};

export const nonConformingStyle = {
	color: 'red',
	fontWeight: 'bold',
};