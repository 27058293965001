import React, { useState, useMemo, useEffect } from 'react';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Paper, TablePagination, Box, CircularProgress, TableSortLabel, Chip,
	useTheme, Tooltip,
	Typography
} from '@mui/material';
import { Order, PageState } from '../hooks/Interfaces';
import RowActions from './RowActions';
import { SendMessageFunction } from '../../../../../websocket/hooks/types';
import SetupsheetViewHelper from '../../setupsheets/SetupsheetViewHelper';
import {
	armChipStyle, cellStyle, lineplanChipStyle, loadedChipStyle, machineChipStyle,
	nonConformingStyle, stickyColumnHeaderStyle, stickyColumnStyle, stickyHeaderStyle, unitStyle
} from './OrderTableStyles';

interface OrderTableProps {
	pageState: PageState;
	orders: Order[];
	sendMessage: SendMessageFunction;
}

const OrderTable: React.FC<OrderTableProps> = ({ orders, sendMessage, pageState }) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);

	const [setupsheetViewOpen, setSetupsheetViewOpen] = useState<boolean>(false);
	const [selectedItemName, setSelectedItemName] = useState<string | null>(null);
	const [currentItemType, setCurrentItemType] = useState<string | null>(pageState.sort_by.item_type);

	const theme = useTheme();

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const itemTypes = [null, 'Moldable', 'Assembly', 'Powder', 'Component', 'Kit'];

	const handleItemTypeClick = () => {
		const currentIndex = itemTypes.indexOf(currentItemType || itemTypes[0]);
		const nextIndex = (currentIndex + 1) % itemTypes.length;
		const nextItemType = itemTypes[nextIndex];

		// Update the current item type in state
		setCurrentItemType(nextItemType);

		// Update the pageState with the new item type for sorting
		pageState.sort_by.item_type = nextItemType;

		// Call sendMessage with the updated pageState
		sendMessage('update_page_state', { page_state: pageState });
	};

	const handleItemNameClick = (itemName: string) => {
		setSelectedItemName(itemName);
		setSetupsheetViewOpen(true);
	};

	const handleSetupsheetClose = () => {
		setSetupsheetViewOpen(false);
		setSelectedItemName(null);
	};

	const formatNumber = (num: number | null | undefined) => {
		if (num === null || num === undefined || isNaN(num)) {
			return '';
		}
		return new Intl.NumberFormat('en-US').format(num);
	};

	const truncateString = (str: string, maxLength: number) => {
		if (str && str.length > maxLength) {
			return str.substring(0, maxLength) + '...';
		}
		return str;
	};

	const renderPartsCell = (conforming: number | '-', nonConforming: number | '-') => {
		const formattedConforming = conforming !== '-' ? formatNumber(conforming) : '-';
		const formattedNonConforming = nonConforming !== '-' ? formatNumber(nonConforming) : '';

		return (
			<>
				{formattedConforming}
				{formattedNonConforming && (
					<>
						/
						<span style={nonConformingStyle}>
							{formattedNonConforming}
						</span>
					</>
				)}
			</>
		);
	};

	const renderChips = (order: Order) => {
		const machines = order.scheduled_to.machines;
		const scheduledArms = order.scheduled_to.arms;
		const loadedArms = order.scheduled_to.loaded;
		const lineplans = order.scheduled_to.lineplans;

		return (
			<Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
				{machines.map((machine, index) => (
					<Chip key={`machine-${index}`} label={machine} sx={machineChipStyle} />
				))}
				{scheduledArms.map((arm, index) => (
					<Chip key={`scheduled-arm-${index}`} label={arm} sx={armChipStyle} />
				))}
				{loadedArms.map((arm, index) => (
					<Chip key={`loaded-arm-${index}`} label={arm} sx={loadedChipStyle} />
				))}
				{lineplans.map((lineplan, index) => (
					<Chip key={`lineplan-${index}`} label={lineplan} sx={lineplanChipStyle} />
				))}
			</Box>
		);
	};

	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		// Set mounted to true once orders have been loaded or the component has mounted
		if (orders.length > 0) {
			setMounted(true);
		}
	}, [orders, mounted]);

	return (
		<>
			{orders.length === 0 ? (
				!mounted ?
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '400px' }}>
					<CircularProgress />
				</Box>
				: 
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '400px' }}>
					<Typography variant="h6" color="textSecondary">
						No Orders Found
					</Typography>
				</Box>
			) : (
				<>
					<TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 275px)', overflowY: 'hidden', borderRadius: 3 }}>
						<Table stickyHeader aria-label="Order table">
							<TableHead>
								<TableRow>
									<TableCell sx={stickyColumnHeaderStyle}>
										Number
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...cellStyle, fontWeight: 'bold' }}>
										Item
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...cellStyle, fontWeight: 'bold' }}>
										Description
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...unitStyle, pl: 4 }}>
										{/* Handle the Item Type click */}
										<TableSortLabel
											active={currentItemType != null} // Always show active state
											direction="asc" // Direction isn't relevant since we're cycling
											onClick={handleItemTypeClick}
										>
											{currentItemType ? currentItemType : 'Type'} {/* Display the current item type */}
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...cellStyle }}>
										Ship Date
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...unitStyle }}>
										Quantity
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...unitStyle }}>
										Molded
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...unitStyle }}>
										Secondary
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...unitStyle }}>
										Foamed
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...unitStyle }}>
										Assembly
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...unitStyle }}>
										Shipped
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...cellStyle }}>
										Scheduled To
									</TableCell>
									<TableCell sx={{ ...stickyHeaderStyle, ...unitStyle }}>
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
									{orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => (
									<TableRow key={index} sx={{
										borderBottom: `3px solid ${theme.palette.divider}`, // Thicker border
										backgroundColor: index % 2 === 0 ? theme.palette.background.hover : theme.palette.background.paper // Alternate row shading
									}}>
										<TableCell sx={stickyColumnStyle}>{order.number}</TableCell>

										<TableCell
											sx={{
												...cellStyle,
												fontWeight: 'bold',
												cursor: order.has_setupsheet ? 'pointer' : 'default',
												color: order.has_setupsheet ? theme.palette.info.main : 'inherit',
												'&:hover': {
													color: order.has_setupsheet ? theme.palette.info.light : 'inherit',
												},
											}}
											onClick={() => order.has_setupsheet && handleItemNameClick(order.item_name)}
										>
											<Tooltip title={order.has_setupsheet ? "View Setupsheet" : ""} disableHoverListener={!order.has_setupsheet}><span>{order.item_name}</span></Tooltip>
										</TableCell>
										<TableCell sx={{ ...cellStyle }}>{truncateString(order.item_description, 20)}</TableCell>
										<TableCell sx={{ ...unitStyle }}>{order.item_type.string}</TableCell>
										<TableCell sx={{ ...cellStyle }}>{order.ship_date}</TableCell>
										<TableCell sx={{ ...unitStyle }}>{formatNumber(order.quantity)}</TableCell>
										<TableCell sx={{ ...unitStyle }}>{renderPartsCell(order.molded.conforming, order.molded.non_conforming)}</TableCell>
										<TableCell sx={{ ...unitStyle }}>{renderPartsCell(order.secondary.conforming, order.secondary.non_conforming)}</TableCell>
										<TableCell sx={{ ...unitStyle }}>{renderPartsCell(order.foamed.conforming, order.foamed.non_conforming)}</TableCell>
										<TableCell sx={{ ...unitStyle }}>{renderPartsCell(order.assembled.conforming, order.assembled.non_conforming)}</TableCell>
										<TableCell sx={{ ...unitStyle }}>{renderPartsCell(order.shipped.conforming, order.shipped.non_conforming)}</TableCell>
										<TableCell sx={{ ...cellStyle }}>{renderChips(order)}</TableCell>
										<TableCell sx={{ ...unitStyle }}>
											<RowActions order={order} sendMessage={sendMessage} />
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<TablePagination
						component="div"
						count={orders.length}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			)}

			{/* Setupsheet Viewer */}
			<SetupsheetViewHelper
				open={setupsheetViewOpen}
				doClose={handleSetupsheetClose}
				itemName={selectedItemName ?? ''}
				machineSelectable
			/>
		</>
	);
};

export default OrderTable;